$FONT_EXTRA_LARGE: 2.5rem;
$FONT_LARGE: 1.5rem;
$FONT_MEDIUM_LARGE: 1.5rem;
$FONT_MEDIUM: 0.9rem;
$FONT_MEDIUM_SMALL: 1.125rem;
$FONT_SMALL: 1rem;
$FONT_EXTRA_SMALL: 0.8rem;

$HEIGHT_EXTRA_LARGE: 5.5rem;
$HEIGHT_LARGE: 4.5rem;
$HEIGHT_MEDIUM_LARGE: 4rem;
$HEIGHT_MEDIUM: 3.75rem;
$HEIGHT_MEDIUM_SMALL: 3.5rem;
$HEIGHT_SMALL: 2.5rem;
$HEIGHT_EXTRA_SMALL: 2rem;
$HEIGHT_TWO_EXTRA_SMALL: 1.5rem;

$LARGE: 24px;
$MEDIUM_LARGE: 16px;
$MEDIUM: 12px;
$MEDIUM_SMALL: 10px;
$SMALL: 8px;
$EXTRA_SMALL: 6px;