#project-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  padding-top: 24px;
}

#no-project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;

  img {
    width: 64px;
  }

  #title {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
  }

  #sub-title {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
  }
}
