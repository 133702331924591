#track-tag {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  white-space: nowrap;
}

#track-tag-list {
  display: flex;
  flex-wrap: wrap;
}
