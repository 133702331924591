button {
  border-style: none;
  cursor: pointer;
}

.signup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .profile {
    width: 80px;
    border-radius: 50%;
  }

  .email {
    font-size: 16px;
    font-weight: 500;
    color: #999;
    margin-bottom: 3rem;
  }

  input {
    ::placeholder {
      text-align: center;
    }
    
    width: 100%;
    border-style: none;
    border-bottom: solid 2px #999;
    padding-bottom: 0.7rem;
    background-color: transparent;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }

  input:focus {
    outline: none;
  }

  .tooltip {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding-top: 0.5rem;

    span {
      color: #555;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .signup-button {
    min-width: 100%;
    min-height: 56px;
    border-radius: 2rem;
    margin-top: 3rem;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    background-color: #b1ff00;
  }
}