.top-time-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4px;

  .bar-number {
    height: 20px;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #999;
  }

  .small {
    width: 4px;
    height: 0.5rem;
    border-radius: 2px;
    background-color: #555;
  }

  .big {
    width: 4px;
    height: 1rem;
    border-radius: 2px;
    background-color: #999;
  }
}
