.flex-row-8 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flex-row-4 {
  display: flex;
  gap: 4px;
}

.flex-col-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#profile {
  width: 35px;
  height: 35px;
  border-radius: 999px;
  background-color: #404040;
}

#nickname {
  font-size: 1rem;
  color: #999;
  font-weight: 500;
}

#divider {
  width: 2px;
  height: 1rem;
  background-color: #555;
}

#tag {
  > img {
    width: 24px;
    height: 24px;
  }

  font-size: 1rem;
}
