.span {
  margin: 0 37px 12px 0;
  font-family: NotoSansKR;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -1px;
  text-align: left;
  color: #fff;
}
