#bpm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 2rem;
  border-radius: 16px;
  background-color: #555;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.8px;
  color: #fff;
}