$Color_Main: #B1FF00;
$Color_Main_Hover: #85bf00;
$Color_Sub_White: #FFFFFF;
$Color_White_Hover: #e0e0e0;
$Color_Sub_Gray: #555555;
$Color_Sub_Red: #FF3E3E;
$Color_BG_Main: #1B1B1B;
$Color_BG_Sub: #0A0A0A;

$Font_Main: #ffffff;
$Font_Sub: #b1ff00;
