#track-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 2.3rem;
  height: 2rem;
  padding-bottom: 0.5rem;
  background-color: transparent;

  img {
    width: 2rem;
    height: 2rem;
    padding-right: 0.2rem;
  }

  span {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -1px;
    color: #fff;
  }
}
