#track-space {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-left: 80px;
  padding-right: 6px;
  background-color: transparent;
  position: relative;
  overflow-y: hidden;
}
