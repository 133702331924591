#track-setting {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 85vh;
}

#top-section {
  display: flex;
  max-height: calc(100% - 8rem);

  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 20%;
    min-width: 16rem;
  }

  #project-info {
    width: 20%;
    min-width: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.8rem;
    background-color: #242424;
    border-radius: 24px;

    & > p {
      font-size: 1.3rem;
      font-weight: 700;
      color: white;
      margin: 1rem 0 0;
    }
  }

  #setting-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem;
    background-color: #242424;
    border-radius: 24px;

    #setting-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      input:focus {
        outline: none;
      }
    }
  }

  .track-title {
    width: 100%;
    height: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    background-color: #242424;
    border: 0;
    border-bottom: 1px solid #555;

    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}

#bottom-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
