#play-stick {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12px;
  height: 100%;
  cursor: pointer;

  #handle {
    width: 12px;
    height: 12px;
    border-radius: 24px;
    background-color: #b1ff00;
  }

  #stick {
    width: 2px;
    height: 100%;
    border-radius: 5px;
    background-color: #b1ff00;
  }
}
