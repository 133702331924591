.track-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.9rem;
  height: 1.9rem;
  position: relative;
  border: solid 2px #242424;
  border-radius: 24px;
  background-color: #fff;

  img {
    width: 1.2rem;
  }
}
