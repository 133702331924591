#detail-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 70vh;
  border-radius: 24px;
  background-color: #242424;

  #detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0 1rem;

    #detail-left-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 5rem;

      .line {
        display: flex;
      }
    }
  }

  #detail-body {
    display: flex;
    height: 80vh;
    overflow-y: scroll;
    padding: 0 1rem;
  }
}
