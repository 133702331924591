@import "utils/style/size";

#bpm-bar {
  width: 100%;
  height: 5.6rem;
  padding-top: 2rem;

  .slide-container {
    width: 100%;
    height: 85%;
    position: relative;

    & > .slider {
      -webkit-appearance: none;
      width: calc(100% - 40px);
      height: 0.6rem;
      margin: 0 20px;
      position: absolute;
      top: 1rem;
      border-radius: 9px;
      background-color: #555;
      outline: none;
    }

    & > .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 2rem;
      height: 2rem;
      border-radius: 24px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
      background-color: #fff;
      z-index: 2;
    }

    & > .target-fill {
      height: 0.6rem;
      margin: 0 20px;
      position: absolute;
      top: 2rem;
      border-radius: 9px;
      background-color: #fff;
      outline: none;
      -webkit-appearance: none;
    }

    & > #output {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2rem;
      position: absolute;
      top: 0.4rem;
      font-size: $FONT_EXTRA_SMALL;
      font-weight: bold;
      color: black;
      cursor: pointer;
      z-index: 10;
    }

    & > .slider-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(95% + 12px);
      position: absolute;
      bottom: 12px;
      font-size: $FONT_EXTRA_SMALL;
      font-weight: 500;
      color: #999;
    }
  }
}
