.like-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 1.5rem;
  padding: 2px 8px 2px 4px;
  margin-left: 1rem;
  border-radius: 17px;
  border: solid 1px #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;

  img {
    width: 1.5rem;
  }

  span {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 2.3;
    letter-spacing: -0.7px;
    color: #fff;
  }
}

.like-by-detail {
  width: 3.8rem;
  height: 2.3rem;
  border-radius: 20px;
}
