#sound-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.5rem;
  margin-left: 20px;

  img {
    width: 15px;
  }
}