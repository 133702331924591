@import "utils/style/color";

.play-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;

  .play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: $Color_Main;
    border-radius: 24px;

    &:hover {
      background-color: #85bf00;
    }

    img {
      width: 1.2rem;
    }
  }

  .under-play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 24px;

    img {
      width: 2.5rem;
    }
  }

  span {
    padding-left: 10px;
    font-family: "KronaOne";
    font-size: 1.2rem;
    line-height: 1.25;
    letter-spacing: -0.8px;
    text-align: center;
    color: $Color_Main;
  }
}
