#record-box {
  width: calc(100% - 1px);
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  cursor: initial;
}
