#under-play-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6rem;
  margin-top: 1rem;
  border-radius: 24px;
  background-color: #242424;

  & > #play-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 9rem;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
  }
}
