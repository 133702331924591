@import "utils/style/color";
@import "utils/style/size";

#record-device {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 2rem;
  white-space: nowrap;
}

.selected-device {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 1.7rem;
  width: 100%;
  padding: 4px 0;
  border-radius: 24px;
  background-color: #555;
  color: $Font_Main;

  span {
    padding: 8px;
    margin-left: 12px;
    font-size: 0.8rem;
  }

  & > button {
    background-color: rgba(0, 0, 0, 0);
  }
}

.device-list {
  position: absolute;
  top: 110px;
  width: calc(100% - 32px);
  height: 128px;
  margin-top: 8px;
  padding: 8px 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  background-color: #555;
  color: $Font_Main;
  z-index: 999;

  & .scroll-box {
    height: 100%;
    overflow: scroll;
  }

  & ul {
    width: 95%;
    padding: 0;
    margin: 0;
    list-style: none;

    & li {
      padding: 4px 16px;
      margin-top: 8px;
      font-size: 0.8rem;
    }

    & li:hover {
      border-radius: 18px;
      background-color: #404040;
    }
  }
}

.hidden-device-list {
  display: none;
}

.rotate-button {
  transform: scaleY(-1);
}
